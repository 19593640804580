<script>
import pagination from "@/mixins/pagination";

import format from "date-fns/format";
import patientTaskSevice from "@/services/patient-task";
import alertsService from "@/services/alerts";
import helperService from "@/services/helper";

import AlertBadge from "@/components/ui/AlertBadge";
import Spinner from "@/components/ui/Spinner";

export default {
  name: "ModalPatientTasksList",

  mixins: [pagination],

  components: {
    AlertBadge,
    Spinner,
  },

  data() {
    return {
      isLoading: false,
      tasks: [],
      from: "",
      to: "",
    };
  },

  props: {
    patientId: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: "",
    },
    showDialogModal: {
      type: Boolean,
      default: false,
    },
  },

  created() {
    this.getTasks();
  },

  computed: {
    filteredTasks() {
      if (!this.from && !this.to) {
        return this.tasks;
      }

      const from = this.from && format(new Date(this.from), "dd/MM/yyyy");
      const to = this.to && format(new Date(this.to), "dd/MM/yyyy");

      return this.tasks.filter((task) => {
        const completedAt = format(new Date(task.completedAt), "dd/MM/yyyy");

        if (this.from && this.to) {
          return completedAt >= from && completedAt <= to;
        } else if (this.from) {
          return completedAt >= from;
        } else {
          return completedAt <= to;
        }
      });
    },

    showAnswer() {
      const isCheckins = this.type === "checkins" || this.name === "checkins";
      return isCheckins;
    },
  },

  methods: {
    close() {
      this.$emit("close");
    },

    getTasks() {
      this.isLoading = true;

      const query = helperService.buildQuery({
        offset: this.pagination.offset,
        limit: this.pagination.limit,
        ...(this.type === "vitals" ? { vitalMatch: this.name } : {}),
        queryProperties: ["answer", "name"],
        sort: { completedAt: -1, updatedAt: -1 },
      });

      return patientTaskSevice
        .get({
          patientID: this.patientId,
          taskType: this.type || this.name,
          query,
        })
        .then((res) => {
          this.tasks = res.tasks.map((task) => {
            task.serverCompletedMeasurement = task.completedMeasurement;
            const { color, shouldBeCompleted } =
              alertsService.calculateMeasurement(task);
            task.completedMeasurement = color;
            task.shouldBeCompleted = shouldBeCompleted;

            return task;
          });
          this.tasks = res.tasks;
          this.pagination.total = res.total;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    getAnswer(task) {
      if (this.type === "vitals") {
        return task.answer;
      }

      const answer = (task.answers || []).find(
        (answer) => answer._id === task.answer
      );

      if (answer) {
        return answer.content;
      }
    },

    onPageChange() {
      this.getTasks();
    },
  },
};
</script>

<template lang="pug">
  el-dialog.modal-container(name="modal-patient-task-list", :visible.sync="showDialogModal", :before-close="close", width="70%")
    .head__actions
      .head__search
        label Desde:
        el-date-picker(lang="es", placeholder="Seleccionar Fecha", v-model="from", @clear="from = ''")
      .head__search
        label Hasta:
        el-date-picker(lang="es", placeholder="Seleccionar Fecha", v-model="to", @clear="to = ''")
    .box
      .box__content--stretch
        table
          thead
            tr
              th Nombre
              th(v-if="showAnswer") Valor
              th Fecha completada
              th Fecha planificada
              th Estado
          tbody
            spinner(v-if="isLoading")
            tr(v-else v-for="(task, index) in filteredTasks" :key="task.id")
              td {{ task.name | capitalize }}
              td(v-if="showAnswer") {{ getAnswer(task) }}
              td(v-if="task.completedAt") {{ task.completedAt | formatDateAndTime }}
              td(v-else) -
              td {{ task.scheduledAt | formatDateAndTime }}
              td
                alert-badge(:alert="task")
      pagination(
        :isLoading="isLoading"
        :limit="pagination.limit",
        :total="pagination.total",
        @pagination="setPagination"
      )
    .footer
      fieldset(style="margin-left: auto")
        button.button.button--blue(@click="close") Salir
</template>

<style lang="scss" scoped>
.disabled {
  cursor: none;
  pointer-events: none;
}
.head__actions {
  display: flex;
  justify-content: space-evenly;
  margin: 15px 0;
}
.head__search > * {
  margin: 0 0 0 15px;
}
.head__search {
  display: flex;
  align-items: center;
}
.box {
  margin: 10px;
}
.footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.box__footer {
  position: inherit;
  width: 100%;
}
</style>
