<script>
import Spinner from "@/components/ui/Spinner";
import { mapGetters } from "vuex";
import FormBox from "@/components/patient/dashboard/FormBox";
import IndicationBox from "@/components/patient/dashboard/IndicationBox";
import NotesBox from "@/components/patient/dashboard/NotesBox";
import { isIndicationsEnabled } from "@/services/config";
import patientService from "@/services/patient";
import episodeService from "@/services/episode";
import helperService from "@/services/helper";
import VideoRoom from "@/components/video-room/VideoRoom";

export default {
  name: "PatientVideoRoomView",
  components: {
    Spinner,
    FormBox,
    IndicationBox,
    NotesBox,
    VideoRoom,
  },

  data() {
    return {
      patient: "",
      videoRoomToken: "",
      isLoadingPatientData: true,
      isLoading: false,
      roomInfo: {},
      activeEpisode: {},
      isIndicationsEnabled,
    };
  },

  computed: {
    ...mapGetters([]),
  },

  created() {
    this.fetchPatientData();
  },

  methods: {
    async fetchPatientData() {
      try {
        const patient = await patientService.getById(this.$route.params.id);
        this.patient = patient;
        const activeEpisode = await episodeService.getActive(this.patient._id);
        this.activeEpisode = activeEpisode;
        this.isLoadingPatientData = false;
      } catch (error) {
        console.log(error);
      }
    },

    goBack() {
      this.$router.back();
    },

    goToPatientDashboardTab() {
      let routeData = this.$router.resolve({
        name: "patient-dashboard",
        params: { id: this.patient._id },
      });
      window.open(routeData.href, "_blank");
    },

    getFullName(user) {
      return helperService.getFullName(user);
    },

    getGender(user) {
      switch (user.gender) {
        case "m":
          return "Hombre";

        case "f":
          return "Mujer";

        default:
          return "Otro";
      }
    },
  },
};
</script>

<template lang="pug">
    section
        header.headline
            .headline__title
              .back(@click="goBack()")
                micon(name="arrow_back" width="30px" height="30px")
              .title  
                h1
                  | Videollamada - 
                  .patient(@click='goToPatientDashboardTab()') {{ getFullName(patient) }}
            h3(v-if="patient")
              | {{ getGender(patient) }} | {{ patient.bornAt | age }} | {{ patient.governmentId.type.toUpperCase()}} {{ patient.governmentId.number }}
        article.video-room(v-if="patient")
          video-room(:patientId="patient._id")
          nav.sidebar
            h2
              | Información del paciente
            .patientData(v-if="!isLoadingPatientData")
              form-box(:patient="patient" isCollapsable :activeEpisode="activeEpisode")
              indication-box(v-if='isIndicationsEnabled' :patient="patient" :activeEpisode="activeEpisode" isCollapsable)
              notes-box(v-model="patient" isCollapsable)
</template>

<style lang="scss" scoped>
.headline {
  flex-direction: column;
}

.headline__title {
  display: flex;
  align-items: center;
}

.patient {
  text-decoration: underline;
  cursor: pointer;
  display: inline;
}

.back {
  margin-right: 10px;
}

h2 {
  margin-bottom: 25px;
  text-align: center;
}

h3 {
  margin-top: 15px;
  margin-left: 10px;
}

.video-room {
  display: grid;
  grid-template-columns: auto auto;
  height: 75vh;

  .sidebar {
    height: 100%;
    max-height: 70vh;
    margin-top: -50px;
    width: 26vw;
    margin-left: auto;

    .patientData {
      height: 100%;
      overflow: scroll;
      padding: 0 10px 0 0;
    }
  }
}
</style>
