import { isBefore, addDays, parseISO } from "date-fns";
import { TASKS_TYPES } from "./constants";
import patientTaskService from "./patient-task";

const alertsService = {};

const defaultSortQuery = {
  sort: { completedAt: -1, updatedAt: -1 },
};

alertsService.getAllAlerts = function ({
  patientID,
  reportsQuery = {},
  query = {},
  withReports = true,
}) {
  if (!patientID) {
    return new Error("Missing PatientID");
  }
  const promises = Object.keys(TASKS_TYPES)
    .filter((type) => (withReports ? true : type !== "reports"))
    .map((type) => {
      const _query = type === "reports" ? reportsQuery : query;
      return getAlertPromise({
        patientID,
        type,
        query: { ...defaultSortQuery, ..._query },
      });
    });

  return Promise.all(promises).then((alerts) => {
    return alerts.reduce((acc, alert) => {
      return [
        ...acc,
        ...alert.tasks
          .filter((a) => a.patient)
          .map((a) => {
            // don't need to calculate incompleted vitals
            if (alert.taskType === "vitals") {
              return { ...a, type: alert.taskType };
            }

            const { color, shouldBeCompleted } =
              alertsService.calculateMeasurement(a);
            a.serverCompletedMeasurement = a.completedMeasurement;
            a.completedMeasurement = color;
            a.shouldBeCompleted = shouldBeCompleted;
            return { ...a, type: alert.taskType };
          }),
      ];
    }, []);
  });
};

alertsService.separateAlerts = function ({ alerts, type = "vitals" }) {
  return alerts.reduce(
    (acc, alert) => {
      if (alert.type === type) {
        return {
          [type]: [...acc[type], alert],
          rest: acc.rest,
        };
      }

      return {
        [type]: acc[type],
        rest: [...acc.rest, alert],
      };
    },
    {
      [type]: [],
      rest: [],
    }
  );
};

alertsService.groupBy = function ({ alerts, limit = 6, prop = "name" }) {
  return alerts.reduce((acc, alert) => {
    const name = alert[prop];
    if (!acc[name]) {
      acc[name] = [];
    }
    acc[name].push(alert);
    acc[name] = acc[name].slice(0, limit);
    return acc;
  }, {});
};

function getAlertPromise({ patientID = null, type, query }) {
  return patientTaskService.get({ patientID, taskType: type, query });
}

alertsService.calculateMeasurement = function (task) {
  const shouldBeCompleted =
    !task.completedMeasurement &&
    !task.completedAt &&
    isBefore(addDays(parseISO(task.scheduledAt), 1), new Date());

  if (shouldBeCompleted) {
    return { color: "red", shouldBeCompleted: true };
  }

  return { color: task.completedMeasurement, shouldBeCompleted: false };
};

export default alertsService;
