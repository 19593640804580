<script>
import pagination from "@/mixins/pagination";

import { compareAsc } from "date-fns";
import patientService from "@/services/patient";
import patientTaskSevice from "@/services/patient-task";
import patientTaskService from "@/services/patient-task";
import helperService from "@/services/helper";
import { TASKS_TYPES } from "@/services/constants";

import CalendarCell from "@/components/ui/CalendarCell";
import Spinner from "@/components/ui/Spinner";

export default {
  name: "PatientTasks",

  mixins: [pagination],

  components: {
    Spinner,
    CalendarCell,
  },

  data() {
    return {
      patientId: this.$route.params.id,
      TASKS_TYPES: TASKS_TYPES,
      isLoading: false,
      patientName: "",
      patientTasks: [],
      viewType: "calendar",
      taskTypeFilter: this.$route.query.taskType
        ? [this.$route.query.taskType]
        : [],
      searchFilter: "",
      statusOptions: [
        {
          text: "Sin Completar",
          value: "not-completed",
        },
        {
          text: "Completadas",
          value: "completed",
        },
      ],
      statusFilter: "",
    };
  },

  created() {
    this.isLoading = true;
    Promise.all([this.getPatientName(), this.getAllTasks()]).finally(() => {
      this.isLoading = false;
    });
  },

  computed: {
    filteredTasks() {
      const includesQuery = (string = "") => {
        const searchFilter = this.searchFilter.toLocaleLowerCase();
        return string.toLocaleLowerCase().includes(searchFilter);
      };

      return this.patientTasks.filter((task) => {
        const matchesSearchQuery =
          includesQuery(task.name) || includesQuery(task.description);

        const matchesType = this.taskTypeFilter.length
          ? this.taskTypeFilter.includes(task.type)
          : true;

        let matchesStatus;

        if (this.statusFilter === "completed") {
          matchesStatus = task.completedAt;
        } else if (this.statusFilter === "not-completed") {
          matchesStatus = !task.completedAt;
        } else {
          matchesStatus = true;
        }

        return matchesSearchQuery && matchesType && matchesStatus;
      });
    },

    paginatedTasks() {
      return this.filteredTasks.slice(
        this.pagination.offset,
        this.pagination.limit * this.pagination.currentPage
      );
    },

    tasksByDate() {
      return this.filteredTasks.reduce((acc, task) => {
        if (!task.scheduledAt) {
          return acc;
        }

        const date = task.scheduledAt.split("T", 1)[0];

        if (!acc[date]) {
          acc[date] = [];
        }

        acc[date].push(task);

        return acc;
      }, {});
    },
  },

  methods: {
    getPatientName() {
      return patientService.getById(this.patientId).then((patient) => {
        this.patientName = helperService.getFullName(patient);
      });
    },

    getAllTasks() {
      return Promise.all(
        Object.keys(TASKS_TYPES).map((type) =>
          patientTaskSevice
            .get({ patientID: this.patientId, taskType: type })
            .then((tasksResponse) => {
              const { tasks } = tasksResponse;
              return tasks.map((task) => ({ ...task, type }));
            })
        )
      ).then((tasks) => {
        this.patientTasks = tasks
          .flat()
          .sort((a, b) =>
            compareAsc(new Date(a.scheduledAt), new Date(b.scheduledAt))
          );
      });
    },

    onPageChange() {
      this.getAllTasks();
    },

    deleteTask(task) {
      this.$confirm(`¿Desea eliminar la tarea: ${task.name}?`, "Eliminar", {
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
        type: "warning",
      })
        .then(() => {
          patientTaskService.delete({
            taskType: task.type,
            taskID: task._id,
          });

          this.patientTasks = this.patientTasks.filter(
            (t) => t._id !== task._id
          );
        })
        .catch(() => {});
    },
  },
};
</script>

<template lang="pug">
  section.patient-tasks
    article.row(v-if="isLoading")
      spinner

    header.headline(v-else)
      .headline__title
        .back(@click="$router.back()")
          micon(name="arrow_back" width="30px" height="30px")
        .title
          h1
            | Tareas de {{ patientName | capitalize }}
            small  ({{filteredTasks.length}})
          hr

      .headline__actions
        el-input(prefix-icon="el-icon-search" v-model="searchFilter" placeholder="Buscar" :clearable="true")
        el-select(v-model="taskTypeFilter" multiple collapse-tags clearable placeholder="Tipo")
          el-option(
            v-for="option in TASKS_TYPES"
            :key="option.type"
            :label="option.name"
            :value="option.type"
          )
        el-select(v-model="statusFilter" clearable placeholder="Estado")
          el-option(
            v-for="option in statusOptions"
            :key="option.value"
            :label="option.text"
            :value="option.value"
          )

        .toggle
          button.button.toggle__button(@click="viewType = 'calendar'" :class="{'is-active': viewType === 'calendar'}")
            micon(name="apps").toggle__icon
          button.button.toggle__button(@click="viewType = 'list'" style="margin-left: 0" :class="{'is-active': viewType === 'list'}")
            micon(name="list").toggle__icon

    article.row(v-if="!isLoading && viewType === 'calendar'")
      el-calendar.patient-tasks-calendar(:first-day-of-week="7")
        template(v-slot:dateCell="data")
          calendar-cell(:day="data.data.day" :tasks="tasksByDate[data.data.day]")

    .box(v-if="!isLoading && viewType === 'list'")
      .box__content--stretch
        table
          thead
            tr
              th Nombre
              th Descripción
              th Tipo
              th Fecha de creación
              th Fecha completada
              th Fecha planificada
              th Acciones
          tbody
            tr(v-for="task in paginatedTasks", :key="task._id")
              td {{ task.name | capitalize }}
              td {{ task.description }}
              td {{ TASKS_TYPES[task.type].name }}
              td {{ task.createdAt | formatDateAndTime }}
              td(v-if="task.completedAt") {{ task.completedAt | formatDateAndTime }}
              td(v-else) -
              td {{ task.scheduledAt | formatDateAndTime }}
              td.actions
                .actions-container
                  router-link.button.button--action(:to="{ name: 'update-patient-task', params: { taskID: task._id, taskType: task.type }}", v-tooltip="'Editar tarea'" )
                    micon(name="edit")
                  button.button.button--action(@click="deleteTask(task)", v-tooltip="'Eliminar tarea'")
                    micon(name="delete_forever")

      pagination(
        :isLoading="isLoading"
        :limit="pagination.limit"
        :total="filteredTasks.length"
        @pagination="setPagination"
      )

    router-view
</template>

<style lang="scss">
.patient-tasks {
  .headline__title {
    display: flex;
    align-items: center;

    .back {
      cursor: pointer;
      margin-right: 10px;
    }
  }
}

.patient-tasks-calendar {
  .el-calendar-day {
    cursor: default !important;
  }
}

.el-calendar {
  td {
    height: 130px;
  }
}
</style>
