<script>
import Spinner from "@/components/ui/Spinner";
import { s3Url } from "@/services/config";
import { mapGetters } from "vuex";
import ModalPatientForm from "@/components/patient/dashboard/ModalPatientForm";
import formService from "@/services/form";

export default {
  name: "FormBox",

  props: {
    patient: {
      type: Object,
      required: true,
    },
    isCollapsable: {
      type: Boolean,
      default: false,
    },
    activeEpisode: {
      type: Object,
    },
  },

  components: {
    Spinner,
    ModalPatientForm,
  },

  data() {
    return {
      isModalVisible: false,
      patientForms: [],
      selectedPatientForm: {},
      isLoading: false,
      s3Url,
      isContentHidden: this.isCollapsable,
    };
  },

  created() {
    this.getData();

    this.$bus.$on("form-created", (form) => {
      this.patientForms.unshift(form);
    });
  },

  computed: {
    ...mapGetters(["user"]),
  },

  methods: {
    async getData() {
      this.isLoading = true;
      try {
        const patientForms = await formService.getPatientFormsByPatient(
          this.patient._id
        );
        this.patientForms = patientForms.reverse();
      } finally {
        this.isLoading = false;
      }
    },

    showFormModal() {
      this.isModalVisible = true;
    },

    setSelectedPatientForm(form) {
      this.selectedPatientForm = form;
      this.showFormModal();
    },

    closeFormModal() {
      this.isModalVisible = false;
      this.selectedPatientForm = {};
    },

    getFileName(key = "") {
      return key.split("/").pop();
    },

    toggleHideContent() {
      this.isContentHidden = !this.isContentHidden;
    },
  },
};
</script>

<template lang="pug">
  .box.form-box
    .header
      .expand(v-if="isCollapsable" @click='toggleHideContent()' :class="isContentHidden ? 'rotate' : ''")
          micon(name="expand_more")
      h1 Estados
      .buttons
        .new-patient-event.box__icon(v-if="activeEpisode" @click="showFormModal()")
          micon(v-show="!isContentHidden" name="add" v-tooltip="'Agregar estado'")
    .content(:class="(isCollapsable && isContentHidden) ? 'hide-content' : '' ")
      .no-data(v-if="!isLoading && !patientForms.length") No hay eventos

      spinner(v-if="isLoading")
      el-timeline(v-else)
        el-timeline-item(
          v-for="form in patientForms",
          :key="form._id",
        )
          .box.clickable(@click="setSelectedPatientForm(form)")
            h4.underline {{form.name}}
              micon(
                v-if="form.fileSection.files && form.fileSection.files.length",
                name="attach_file",
                height="20px",
                width="20px",
                v-tooltip="'Archivos Adjuntos'"
              )
            h4.date(v-if="form.answeredAt") Hace {{ form.answeredAt | formatDistanceStrict }}
            h4(v-if="form.createdBy") {{ form.createdBy.firstName }} {{ form.createdBy.lastName }}

    ModalPatientForm(
      v-if="isModalVisible"
      :patient='patient'
      :selectedPatientForm='selectedPatientForm'
      :activeEpisode="activeEpisode"
      @closeModal="closeFormModal"
    )

    
</template>

<style lang="scss">
.form-box {
  .el-timeline-item {
    &:last-child {
      padding-bottom: 0;
    }
    &__timestamp {
      margin: 0;
    }
  }
}
</style>
<style lang="scss" scoped>
.form-box {
  .header {
    position: sticky;
    top: 0;
  }

  .expand {
    cursor: pointer;
    transition: all 0.4s ease-in-out;
    transform: rotate(180deg);
  }

  .rotate {
    transform: rotate(0deg);
  }

  .content {
    max-height: 400px;
    visibility: visible;
    transition: all 0.4s ease-in-out;

    &.hide-content {
      visibility: hidden;
      max-height: 0;
      padding-top: 0;
      padding-bottom: 0;
      transition: all 0.4s ease-in-out;
      overflow: hidden;
    }

    .box {
      flex-direction: column;
      margin: 0;
      align-items: flex-start;
      .micon {
        margin-left: 5px;
      }
    }
  }
}
</style>
