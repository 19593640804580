<script>
import configService from "@/services/config";

export default {
  name: "ModalTaskDetail",

  props: {
    task: {
      type: Object,
      required: true,
    },
    showDialogModal: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      s3Url: configService.s3Url,
    };
  },

  methods: {
    close() {
      this.$emit("close");
    },

    getAnswer(task) {
      return task.answers.find((answer) => answer._id === task.answer) || {};
    },

    getFileName(key = "") {
      return key.split("/").pop();
    },
  },
};
</script>

<template lang="pug">
  el-dialog(name="modal-task-detail", :visible.sync="showDialogModal", :before-close="close", :title="task.name")
    span(v-if="task.completedAt || task.updatedAt")  - {{ (task.completedAt || task.updatedAt) | formatDateAndTime }}

    .el-dialog__body
      h4(v-if="task.observation") {{ `Observaciones: ${task.observation}` }}
      h4(v-if="task.type === 'checkins'") Pregunta: {{ task.question }}
      h4(v-if="task.type === 'checkins'") Respuesta: {{ getAnswer(task).content }}

      .form-item(v-if="task.files && task.files.length")
        .description Archivos adjuntos:
        .attached-files
          .file(
            v-for="file in task.files"
            v-bind:class="{ image: file.mimeType.includes('image') }"
          )
            a(:href="`${s3Url}/${file.key}`" target="_blank" rel="noopener noreferrer")
              img(
                v-if="file.mimeType.includes('image')"
                :src="`${s3Url}/${file.key}`"
                alt="Archivo adjunto"
              )
              span(v-else) {{ getFileName(file.key) }}

    .el-dialog__footer
      button.button.button--blue.border(@click="close") Salir
</template>

<style lang="scss" scoped>
.attached-files {
  display: flex;
  flex-wrap: wrap;
  .file {
    width: 100%;
    margin: 0 15px 15px 0;

    &:not(.image) a::before {
      content: "→ ";
    }
  }
  .file.image {
    width: 150px;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
    img {
      object-fit: cover;
      height: -webkit-fill-available;
    }
  }
}
</style>
