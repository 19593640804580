import { render, staticRenderFns } from "./ModalIndication.vue?vue&type=template&id=f71c08b4&scoped=true&lang=pug&"
import script from "./ModalIndication.vue?vue&type=script&lang=js&"
export * from "./ModalIndication.vue?vue&type=script&lang=js&"
import style0 from "./ModalIndication.vue?vue&type=style&index=0&id=f71c08b4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f71c08b4",
  null
  
)

export default component.exports