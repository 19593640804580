<script>
import format from "date-fns/format";
import { TASKS_TYPES } from "@/services/constants";

export default {
  name: "VitalBox",

  props: {
    name: String,
    vitals: Array,
  },

  data() {
    return {
      TASKS_TYPES,
    };
  },

  computed: {
    completedVitals() {
      return this.vitals.filter(({ completedAt }) => completedAt);
    },
    lastAlert() {
      return this.completedVitals[0];
    },
  },

  methods: {
    getTooltip(vital) {
      const createdBy = vital.createdBy;
      const patient = vital.patient;
      const isManual = createdBy && createdBy !== patient;
      const completedAt = format(
        new Date(vital.completedAt),
        "dd/MM/yyyy HH:mm"
      );
      const tooltip = `<b>Fecha:</b> ${completedAt} <br> <b>Valor:</b> ${vital.answer}`;
      return `${tooltip} ${isManual ? "<br> Carga Manual" : ""}`;
    },
  },
};
</script>

<template lang="pug">
  .box.vital-box
    .header
      h1 {{ name | capitalize }}
      .buttons
        router-link(:to="{ name: 'patient-tasks', query: { taskType: 'vitals' } }")
          micon(name="calendar", v-tooltip="'Ver tareas'")
        a(@click="$emit('show-modal', { modal: 'task-graph', task: name })")
          micon(name="chart", v-tooltip="'Ver grafico'")
        a(@click="$emit('show-modal', { modal: 'tasks-table', task: name, type: 'vitals' })")
          micon(name="list", v-tooltip="'Ver tabla de tareas'")
        a(@click="$emit('show-modal', { modal: 'add-manual-task', task: lastAlert || vitals[0] })")
          micon.list-add(name="playlist_add", v-tooltip="'Carga manual'")
    .content(v-if="lastAlert")
      .left.box
        .avatar.card__avatar
          micon(:name="TASKS_TYPES['vitals'].icon")
        .answer {{ lastAlert.answer }}
        hr.divider
        .date(v-if="lastAlert.completedAt") Hace {{ lastAlert.completedAt | formatDistanceStrict }}
      .right.box
        table
          tr.info(v-for="(vital, index) in completedVitals", v-if="index < 8")
            td.label(v-if="vital.completedAt") {{ vital.completedAt | formatDistanceStrict }}
            td.block(
              :class="vital.completedMeasurement",
              v-tooltip="{content: getTooltip(vital)}"
            )

    .content(v-else)
      .no-data El paciente todavía no ha cargado datos
</template>

<style lang="scss" scoped>
@import "@/styles/settings/index.scss";
@import "@/styles/tools/index.scss";

.vital-box {
  height: auto;
  .content {
    @include media(mobile-up) {
      display: flex;
    }

    .left,
    .right {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 15px;

      @include media(mobile-up) {
        height: 360px;
      }
    }

    .left {
      @include media(mobile-up) {
        flex: 1;
        margin-bottom: 0;
        margin-right: 7.5px;
      }
      .micon {
        height: 35px;
        width: 35px;
      }

      .answer {
        color: $primary;
        font-size: 50px;
      }

      .divider {
        background: $gainsboro;
        margin: 15px 0;
      }

      .date {
        color: $cuttysark;
        font-size: 20px;
      }
    }

    .right {
      margin-bottom: 0;
      @include media(mobile-up) {
        flex: 1;
        margin-left: 7.5px;
      }

      table {
        width: initial;
        border-collapse: separate;
        border-spacing: 2px;
        td {
          padding: 8px 15px;
          height: auto;
          border: none;
        }
      }
      .label {
        font-size: 17px;
        font-weight: normal;
        color: $cuttysark;
      }
      .block {
        width: 30px;
        height: 30px;
      }
    }
  }
}
</style>
